.available-time {
    // width: 33%;
    // height: 2.8125rem;

    .Mui-disabled {
        color: rgba(0, 0, 0, 0.26) !important;
    }

    button {
        box-shadow: none;
        font-size: 0.813rem;
        // padding: 7px 10px;
        border-radius: 5px;
        // height: 40px;

        &.selected {
            border: 0;
        }
    }

    &.joinWaitListButton {
        button {
            background-color: #FFEBEB;
            background-image: linear-gradient(-45deg, #f0f0f0 46%, #ececec 49%, #bdbcbc 51%, #eee 55%);
            background-size: 8px 8px !important;
            z-index: 2;
            position: relative;
            padding-bottom: 15px;
        }
    }

    &.joinWaitListButtonLabel {
        .MuiButton-label {
            // justify-content: left;
        }
    }

    .MuiButton-label {
        // justify-content: center;
        // font-weight: bold;
    }

    .join-wailist-btn {
        
        // margin-top: -33px;
        // display: block;
        // float: right;
        // margin-right: 10px;
        color: #0088FF;
        text-decoration: underline;
        // font-weight: bold;
        // line-height: 14px;
        cursor: pointer;
        font-size: 9px;
        width: 80px;
        z-index: 5;
        // position: relative;
        text-align: center;
        pointer-events: auto;
        position: absolute;
        bottom: 9px;
        // z-index: 5;
        // font-size: 9px;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.joinAllTimeWaitlistContainer {
    text-align: left;
    font-size: 14px;
    margin-top: 8px !important;

    button {
        // width: 285px;
        font-weight: bold;

        .MuiButton-label {
            text-transform: none !important;
        }
    }
}

.pdTop10 {
    padding-top: 20px !important;
}
.eventsListItem{
    margin: 5px auto 15px !important;
    width: 92.5%;
    cursor: pointer;
}
.smallLoader{
    width: 35px;
    height: 35px;
    position: absolute;
    right: -40px;
    top: 10px;
    svg{
        width: 100%;
        height: 100%;
    }
}
.timeErrorWaitlist {
        text-align: left;
        display: block;
        margin: 0px 0 0 7px !important;
}